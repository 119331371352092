import React, { Component } from "react";
import axios from "axios";
import emailjs from "emailjs-com";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import "./App.css";
import Icon from "./assets/whatsapp.png";

//Whatsapp
// import { FloatingWhatsApp } from 'react-floating-whatsapp'
// import ReactWhatsapp from 'react-whatsapp';
// import { WhatsAppWidget } from 'react-whatsapp-widget';
// import 'react-whatsapp-widget/dist/index.css';

//Section
import HeaderSection from "./components/header-section/header-section";
import HomeSection from "./components/home-section/home-section";
import AboutSection from "./components/aboutus-section/aboutus-section";
import ServicesSection from "./components/services-section/services-section";
import GallerySection from "./components/gallery-section/gallery-section";
import OurTeamSection from "./components/ourteam-section/ourteam-section";
import ContactSection from "./components/contact-section/contact-section";
import ArticlePage from "./components/article-page/article-page";
import MobileNav from "./components/header-section/mobilenav/mobilenav";
import BlogPages from "./components/blog-main/blog-pages";
import BackDrop from "./UI/BackDrop";

import withStyles from "@material-ui/core/styles/withStyles";
import { Helmet } from "react-helmet";
import WhatsappBtn from "./components/whatsappBtn";

const data = async () => {
  return await axios(
    "https://swimjo-cms-8c30864f9588.herokuapp.com/api/articles"
  ).then((i) => {
    let dataList = i["data"];
    console.log(dataList);
    dataList.data.sort(function (a, b) {
      return a["id"] - b["id"];
    });
    console.log(dataList);
    localStorage.setItem("articles", JSON.stringify(dataList));
  });
};

data();

const styles = (theme) => ({
  menuBarContainer: {
    position: "absolute",
    bottom: "0",
    height: "100vh",
    width: "230px",
    backgroundColor: "red",
    left: "0px",
    zIndex: "100",
    "@media(maxWidth: 500px)": {
      width: "320px",
    },
  },
  openMenu: {
    position: "absolute",
    bottom: "0",
    height: "100vh",
    width: "230px",
    backgroundColor: "red",
    zIndex: "100",
    transform: "translate(0)",
    transitionDuration: ".5s",
    transitionTimingFunction: "ease-in",
    "@media(maxWidth: 500px)": {
      width: "320px",
    },
  },
  closeMenu: {
    position: "absolute",
    bottom: "0",
    height: "100vh",
    width: "230px",
    backgroundColor: "red",
    zIndex: "100",
    transform: "translate(-370px)",
    transitionDuration: "1s",
    transitionTimingFunction: "ease-in",
    "@media(maxWidth: 500px)": {
      width: "320px",
    },
  },

  exitIcon: {
    position: "absolute",
    top: "5px",
    right: "5px",
  },
  minSize: {
    minWidth: "300px",
  },
});

export class App extends Component {
  constructor(props) {
    super(props);
    this.homeSection = React.createRef();
    this.aboutusSection = React.createRef();
    this.servicesSection = React.createRef();
    this.gallerySection = React.createRef();
    this.ourTeamSection = React.createRef();
    this.contactSection = React.createRef();
  }

  state = {
    article: null,
    setOpen: false,
    currentBlog: null,
  };

  getBlogInfo = (obj) => {
    this.setState({ article: obj });
    console.log(obj);
  };

  handleClick = (bool) => {
    this.setState({ setOpen: bool });
  };

  currentBlog = (blog) => {
    this.setState({ currentBlog: blog });
  };

  openInNewTab = (url) => {
    console.log("hewww");
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  render() {
    const refObj = {
      homeSection: this.homeSection,
      contactSection: this.contactSection,
      aboutusSection: this.aboutusSection,
      servicesSection: this.servicesSection,
      gallerySection: this.gallerySection,
      ourTeamSection: this.ourTeamSection,
    };

    const { classes } = this.props;
    let menuAnimation = { left: "-230px" };
    if (this.state.setOpen) {
      menuAnimation = classes.openMenu;
    } else if (!this.state.setOpen) {
      menuAnimation = classes.closeMenu;
    }
    //CI=false &&
    return (
      <Router>
        <Helmet>
          <title>Swim Jordan - The Leading Swimming Academy in Jordan</title>
          <meta
            name="description"
            content="One on One swimming classes for all & persons with disabilities of both genders and all ages by former internationally certified swimmers"
          />
        </Helmet>
        <HeaderSection refObj={refObj} handleClick={this.handleClick} />
        <BackDrop
          menuAnimation={this.state.setOpen}
          handleClick={this.handleClick}
        />
        <MobileNav
          menuAnimation={menuAnimation}
          handleClick={this.handleClick}
          refObj={refObj}
        />
        <Switch>
          <Route exact path="/">
            <div>
              <div ref={this.homeSection}>
                <HomeSection refObj={refObj} />
              </div>
              <div ref={this.aboutusSection}>
                <AboutSection />
              </div>
              <div ref={this.servicesSection}>
                <ServicesSection />
              </div>
              <div ref={this.gallerySection}>
                <GallerySection />
              </div>
              <div ref={this.ourTeamSection}>
                <OurTeamSection />
              </div>

              <div ref={this.contactSection}>
                <ContactSection refObj={refObj} />
              </div>
              {/* <ReactWhatsapp number="+9625526407029" message="Hello World!!!" className="whatsappBtn" /> */}
              {/* <WhatsappBtn/> */}
              <div
                className="whatsappBtn"
                onClick={() => this.openInNewTab("https://wa.me/962779410971/")}
              >
                <img src={Icon} className="whatsappIcon" />
              </div>
            </div>
          </Route>

          <Route path={`/blog-page`}>
            <BlogPages getBlog={this.getBlogInfo} />
          </Route>
          <Route exact path={`/article-page/:id/`}>
            <ArticlePage articleData={this.state.article} />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default withStyles(styles)(App);

// import React, { Component } from "react";
// import axios from "axios";
// import emailjs from "emailjs-com";
// import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
// import "./App.css";
// import Icon from "./assets/whatsapp.png";

// //Whatsapp
// // import { FloatingWhatsApp } from 'react-floating-whatsapp'
// // import ReactWhatsapp from 'react-whatsapp';
// // import { WhatsAppWidget } from 'react-whatsapp-widget';
// // import 'react-whatsapp-widget/dist/index.css';

// //Section
// import HeaderSection from "./components/header-section/header-section";
// import HomeSection from "./components/home-section/home-section";
// import AboutSection from "./components/aboutus-section/aboutus-section";
// import ServicesSection from "./components/services-section/services-section";
// import GallerySection from "./components/gallery-section/gallery-section";
// import OurTeamSection from "./components/ourteam-section/ourteam-section";
// import ContactSection from "./components/contact-section/contact-section";
// import ArticlePage from "./components/article-page/article-page";
// import MobileNav from "./components/header-section/mobilenav/mobilenav";
// import BlogPages from "./components/blog-main/blog-pages";
// import BackDrop from "./UI/BackDrop";

// import withStyles from "@material-ui/core/styles/withStyles";
// import { Helmet } from "react-helmet";
// import WhatsappBtn from "./components/whatsappBtn";
// import Test1 from "./components/test";

// const data = async () => {
//   return await axios(
//     "https://swimjo-cms-8c30864f9588.herokuapp.com/api/articles"
//   ).then((i) => {
//     let dataList = i.data.data;
//     dataList.sort(function (a, b) {
//       return a["id"] - b["id"];
//       // return a["id"] - b["id"];
//     });
//     console.log(dataList);
//     localStorage.setItem("articles", JSON.stringify(dataList));
//   });
// };

// data();

// export class App extends Component {
//   constructor(props) {
//     super(props);
//     this.homeSection = React.createRef();
//     this.aboutusSection = React.createRef();
//     this.servicesSection = React.createRef();
//     this.gallerySection = React.createRef();
//     this.ourTeamSection = React.createRef();
//     this.contactSection = React.createRef();
//   }

//   state = {
//     article: null,
//     setOpen: false,
//     currentBlog: null,
//   };

//   getBlogInfo = (obj) => {
//     this.setState({ article: obj });
//     console.log(obj);
//   };

//   handleClick = (bool) => {
//     this.setState({ setOpen: bool });
//   };

//   currentBlog = (blog) => {
//     this.setState({ currentBlog: blog });
//   };

//   openInNewTab = (url) => {
//     console.log("hewww");
//     const newWindow = window.open(url, "_blank", "noopener,noreferrer");
//     if (newWindow) newWindow.opener = null;
//   };

//   render() {
//     const refObj = {
//       homeSection: this.homeSection,
//       contactSection: this.contactSection,
//       aboutusSection: this.aboutusSection,
//       servicesSection: this.servicesSection,
//       gallerySection: this.gallerySection,
//       ourTeamSection: this.ourTeamSection,
//     };

//     //CI=false &&
//     return (
//       <Router>
//         <Helmet>
//           <title>Swim Jordan - The Leading Swimming Academy in Jordan</title>
//           <meta
//             name="description"
//             content="One on One swimming classes for all & persons with disabilities of both genders and all ages by former internationally certified swimmers"
//           />
//         </Helmet>
//         <HeaderSection refObj={refObj} handleClick={this.handleClick} />

//         <Switch>
//           <Route exact path="/">
//             <div>
//               <div ref={this.homeSection}>
//                 <HomeSection refObj={refObj} />
//               </div>
//               <div ref={this.aboutusSection}>
//                 <AboutSection />
//               </div>
//               <div ref={this.servicesSection}>
//                 <ServicesSection />
//               </div>
//               <div ref={this.gallerySection}>
//                 <GallerySection />
//               </div>
//               <div ref={this.ourTeamSection}>
//                 <OurTeamSection />
//               </div>

//               <div ref={this.contactSection}>
//                 <ContactSection refObj={refObj} />
//               </div>
//               {/* <ReactWhatsapp number="+9625526407029" message="Hello World!!!" className="whatsappBtn" /> */}
//               {/* <WhatsappBtn/> */}
//               <div
//                 aria-describedby={1}
//                 className="whatsappBtn"
//                 onClick={() => this.openInNewTab("https://wa.me/962779410971/")}
//               >
//                 <div>
//                   <div className="whatsappBox">Contact us here</div>
//                   <img src={Icon} className="whatsappIcon" />
//                 </div>
//               </div>
//             </div>
//           </Route>

//           <Route path={`/blog-page`}>
//             <BlogPages getBlog={this.getBlogInfo} />
//             {/* <Test1 /> */}
//           </Route>
//           <Route exact path={`/article-page/:id/`}>
//             <ArticlePage articleData={this.state.article} />
//           </Route>
//         </Switch>
//       </Router>
//     );
//   }
// }

// export default App;

// import React, { Component, useRef, useState } from "react";
// import axios from "axios";
// import emailjs from "emailjs-com";
// import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
// import "./App.css";
// import Icon from "./assets/whatsapp.png";

// // "@testing-library/jest-dom": "^5.16.5",
// // "@testing-library/react": "^13.4.0",
// // "@testing-library/user-event": "^13.5.0",

// //Section
// import HeaderSection from "./components/header-section/header-section";
// import HomeSection from "./components/home-section/home-section";
// import AboutSection from "./components/aboutus-section/aboutus-section";
// // import ServicesSection from "./components/services-section/services-section";
// import GallerySection from "./components/gallery-section/gallery-section";
// // import OurTeamSection from "./components/ourteam-section/ourteam-section";
// import ContactSection from "./components/contact-section/contact-section";
// import ArticlePage from "./components/article-page/article-page";
// // import MobileNav from "./components/header-section/mobilenav/mobilenav";
// import BlogPages from "./components/blog-main/blog-pages";
// // import BackDrop from "./UI/BackDrop";

// // import withStyles from "@material-ui/core/styles/withStyles";
// import { Helmet } from "react-helmet";
// import WhatsappBtn from "./components/whatsappBtn";
// import Test1 from "./components/test";

// const data = async () => {
//   return await axios(
//     "https://swimjo-cms-8c30864f9588.herokuapp.com/api/articles"
//   ).then((i) => {
//     let dataList = i.data.data;
//     dataList.sort(function (a, b) {
//       return a["id"] - b["id"];
//       // return a["id"] - b["id"];
//     });
//     console.log(dataList);
//     localStorage.setItem("articles", JSON.stringify(dataList));
//   });
// };

// data();

// export function App() {
//   // constructor(props) {
//   //   super(props);
//   //   this.homeSection = React.createRef();
//   //   this.aboutusSection = React.createRef();
//   //   this.servicesSection = React.createRef();
//   //   this.gallerySection = React.createRef();
//   //   this.ourTeamSection = React.createRef();
//   //   this.contactSection = React.createRef();
//   // }

//   const sectionRefs = {
//     homeSection: useRef(null),
//     aboutusSection: useRef(null),
//     servicesSection: useRef(null),
//     gallerySection: useRef(null),
//     ourTeamSection: useRef(null),
//     contactSection: useRef(null),
//   };

//   // state = {
//   //   article: null,
//   //   setOpen: false,
//   //   currentBlog: null,
//   // };

//   const [state, setState] = useState({
//     article: null,
//     setOpen: false,
//     currentBlog: null,
//   });

//   const getBlogInfo = (obj) => {
//     setState((prev) => ({ ...prev, article: obj }));
//     console.log(obj);
//   };

//   const handleClick = (bool) => {
//     setState((prev) => ({ ...prev, setOpen: bool }));
//   };

//   const currentBlog = (blog) => {
//     setState((prev) => ({ ...prev, currentBlog: blog }));
//   };

//   const openInNewTab = (url) => {
//     console.log("hewww");
//     const newWindow = window.open(url, "_blank", "noopener,noreferrer");
//     if (newWindow) newWindow.opener = null;
//   };
//   // const refObj = {
//   //   homeSection: sectionRefs.homeSection,
//   //   contactSection: sectionRefs.contactSection,
//   //   aboutusSection: sectionRefs.aboutusSection,
//   //   servicesSection: sectionRefs.servicesSection,
//   //   gallerySection: sectionRefs.gallerySection,
//   //   ourTeamSection: sectionRefs.ourTeamSection,
//   // };

//   //CI=false &&
//   return (
//     <Router>
//       <Helmet>
//         <title>Swim Jordan - The Leading Swimming Academy in Jordan</title>
//         <meta
//           name="description"
//           content="One on One swimming classes for all & persons with disabilities of both genders and all ages by former internationally certified swimmers"
//         />
//       </Helmet>
//       <HeaderSection
//         // refObj={refObj}
//         handleClick={handleClick}
//       />

//       <Switch>
//         <Route exact path="/">
//           <div>
//             <div ref={sectionRefs.homeSection}>
//               <HomeSection
//               // refObj={refObj}
//               />
//             </div>
//             <div ref={sectionRefs.aboutusSection}>
//               <AboutSection />
//             </div>
//             <div ref={sectionRefs.servicesSection}>
//               {/* <ServicesSection /> */}
//             </div>
//             <div ref={sectionRefs.gallerySection}>
//               <GallerySection />
//             </div>
//             <div ref={sectionRefs.ourTeamSection}>
//               {/* <OurTeamSection /> */}
//             </div>

//             <div ref={sectionRefs.contactSection}>
//               <ContactSection
//               // refObj={refObj}
//               />
//             </div>
//             {/* <ReactWhatsapp number="+9625526407029" message="Hello World!!!" className="whatsappBtn" /> */}
//             {/* <WhatsappBtn/> */}
//             <div
//               aria-describedby={1}
//               className="whatsappBtn"
//               onClick={() => this.openInNewTab("https://wa.me/962779410971/")}
//             >
//               <div>
//                 <div className="whatsappBox">Contact us here</div>
//                 <img src={Icon} className="whatsappIcon" />
//               </div>
//             </div>
//           </div>
//         </Route>

//         <Route path={`/blog-page`}>
//           <BlogPages getBlog={getBlogInfo} />
//           {/* <Test1 /> */}
//         </Route>
//         <Route exact path={`/article-page/:id/`}>
//           <ArticlePage articleData={state.article} />
//         </Route>
//       </Switch>
//     </Router>
//   );
// }

// export default App;
