import React, { Component } from "react";
import "./seperator.css";

export class Seperator extends Component {
  render() {
    return (
      <div
        style={{
          height: "20px",
          width: "100%",
          display: "flex",
          justifyContent: "space-evenly",
          position: "absolute",
          bottom: "0",
        }}
      >
        <div
          style={{
            backgroundColor: "#f1592a",
            height: "20px",
            width: "5%",
          }}
        />

        <div
          style={{
            backgroundImage:
              "repeating-linear-gradient(90deg,white 0 35px,#1dc0e5 35px 70px)",
            height: "20px",
            width: "90%",
          }}
        />
        <div
          style={{
            backgroundColor: "#f1592a",
            height: "20px",
            width: "5%",
          }}
        />
      </div>
    );
  }
}
//className="seperator-bg"
export default Seperator;
