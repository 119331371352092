import React, { Component } from "react";
import "./blog-box.css";

import { Link } from "react-router-dom";
export class BlogBox extends Component {
  sliceLine(s) {
    if (s.length >= 40) {
      const sliced = s.slice(0, 40);
      return sliced + "...";
    } else return s;
  }
  articleStorage() {
    localStorage.setItem("article", JSON.stringify(this.props.data));
  }

  // shortenString(myString) {
  //   var maxLength = 100;
  //   var result = myString.substring(0, maxLength) + "...";
  //   return result;
  // }

  render() {
    const { data, id } = this.props;
    return (
      <Link
        exact
        to={`/article-page/${id}/`}
        style={{ textDecoration: "none" }}
        className="blog-box-container"
        onClick={() => this.props.getBlog(id)}
      >
        <div
          onClick={() => this.articleStorage()}
          // style={{ position: "relative" }}
        >
          <p className="blog-box-title">
            {this.sliceLine(this.props.data["title"])}
          </p>

          <div className="wave wave1"></div>
          <div className="wave wave2"></div>
          <div className="wave wave3"></div>
          <div className="wave wave4"></div>
        </div>
      </Link>
    );
  }
}

/**
 * <p className="blog-box-content">
            {this.sliceLine(this.props.data["content"])}
          </p>
 */

export default BlogBox;

/**
 * <Route path="/article-page">
            <ArticlePage />
          </Route>
 */
