import React, { Component } from "react";
import "./contact-section.css";
import ContactForm from "../contact-form/contact-form";
import CountUp from "react-countup";

// import EmailIcon from "@mui/icons-material/Email";
// import LocationOnIcon from "@mui/icons-material/LocationOn";
// import PhoneIcon from "@mui/icons-material/Phone";

import EmailIcon from "@material-ui/icons/Email";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PhoneIcon from "@material-ui/icons/Phone";
import converlogo from "../../assets/convert-logo.png";

export class ContactSection extends Component {
  state = {
    show: false,
  };
  render() {
    return (
      <div className="contact-section" onScroll={() => console.log("heww")}>
        <div className="contact-all-container">
          <div className="contact-form-section" style={{ zIndex: "205" }}>
            <ContactForm />
          </div>
          <div className="contact-info-section" style={{ zIndex: "205" }}>
            <div className="icon-info-container" style={{ zIndex: "205" }}>
              <div className="contact-icon-container">
                <EmailIcon className="contact-icon-font" />
              </div>

              <div>
                <h4 className="icon-contact-info">Info@swimjordan.com</h4>
              </div>
            </div>
            <div className="icon-info-container" style={{ zIndex: "205" }}>
              <div className="contact-icon-container">
                <PhoneIcon className="contact-icon-font" />
              </div>
              <a href="tel:+962779410971" style={{ textDecoration: "none" }}>
                <h4 className="icon-contact-info">+962 7 7941 0971</h4>
              </a>
            </div>
            <div
              className="icon-info-container locationLink"
              style={{ cursor: "pointer", zIndex: "205" }}
              onClick={() =>
                window.open(
                  "https://www.google.com/maps/place/Swim+Jordan/@31.9813897,35.8771816,17z/data=!3m1!4b1!4m5!3m4!1s0x151ca1c427d64bbd:0x225fda45c48c61a3!8m2!3d31.9813897!4d35.8793703?hl=enhttps://www.google.com/maps/place/Swim+Jordan/@31.9813897,35.8771816,17z/data=!3m1!4b1!4m5!3m4!1s0x151ca1c427d64bbd:0x225fda45c48c61a3!8m2!3d31.9813897!4d35.8793703?hl=en"
                )
              }
            >
              <div className="contact-icon-container">
                <LocationOnIcon className="contact-icon-font" />
              </div>
              <div>
                <h4 className="icon-contact-info"> Swim Jordan </h4>
              </div>
            </div>
          </div>
          <div className="by-convert-container">
            <div className="convert-logo-circle">
              <div className="convert-positioning">
                <span className="by-convert-text">Created By</span>
                <br />
                <img src={converlogo} className="convert-logo" />
              </div>
            </div>
          </div>
        </div>
        <div class="bubbles">
          <div class="bubble"></div>
          <div class="bubble"></div>
          <div class="bubble"></div>
          <div class="bubble"></div>
          <div class="bubble"></div>
          <div class="bubble"></div>
          <div class="bubble"></div>
          <div class="bubble"></div>
          <div class="bubble"></div>
          <div class="bubble"></div>
        </div>
      </div>
    );
  }
}

export default ContactSection;

/**
 * <form className="contact-form" noValidate onSubmit={this.sendEmail}>
          <label>Name</label>
          <input type="text" name="name" onChange={this.changeHandler} />
          <label>Email</label>
          <input type="email" name="email" onChange={this.changeHandler} />
          <label>Message</label>
          <textarea name="message" onChange={this.handleParagraphChange} />
          <input type="submit" value="Send" />
        </form>
 */
