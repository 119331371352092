import React, { Component } from "react";
import "./service-card.css";
// import InfoIcon from "@mui/icons-material/Info";
import InfoIcon from "@material-ui/icons/Info";

class ServiceCard extends Component {
  state = {
    active: true,
  };
  toggleClass = () => {
    this.state.active
      ? this.setState({ active: false })
      : this.setState({ active: true });
  };
  render() {
    const { desc, title, image, points } = this.props;
    console.log(points);
    return (
      <div
        id="card"
        // style={{ margin: "0 20px" }}
      >
        <div
          id="blur"
          style={{
            background: `url(${image})`,
            backgroundSize: "cover",
            objectSize: "contain",
            backgroundPosition: "50% 0%",
          }}
        >
          <div id="color"></div>
        </div>
        <div id="profile">
          <div
            className={this.state.active ? "info" : "info active"}
            onClick={this.toggleClass}
          >
            <i className="fa fa-info fa-1x block">
              <InfoIcon className="service-icon" />
            </i>
            <i className="fa fa-angle-down fa-2x block"></i>

            {desc.map((i) => (
              <p className="service-card-text">{i}</p>
            ))}

            <ul>
              {points === undefined
                ? ""
                : points.map((i) => <li style={{ listStyle: "none" }}>{i}</li>)}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
//card__heading-span--3
export default ServiceCard;

/**
 *     <div id="card" style={{ margin: "0 20px" }}>
        <div
          id="blur"
          style={{ background: `url(${image})`, backgroundSize: "contain" }}
        >
          <div id="color"></div>
        </div>
        <div id="profile">
          <p className="service-card-text-front">{title}</p>
          <div
            className={this.state.active ? "info" : "info active"}
            onClick={this.toggleClass}
          >
            <i className="fa fa-info fa-1x block">
              <InfoIcon className="service-icon" />
            </i>
            <i className="fa fa-angle-down fa-2x block"></i>

            <p className="service-card-text">
              In short, I spend my earthly time striving to create some darned
              awesome UI designs, and also develop a few. Student @{" "}
            </p>
          </div>
        </div>
      </div>
 */

/**
 *       <div className="card">
        <div className="card__side card__side--front">
          <div className="card__picture card__picture--3">
            <img className="image" src={Img} alt="img" />
            &nbsp;
          </div>
          <h4 className="card__heading">
            <span className="card__heading-span ">{title}</span>
          </h4>
        </div>
        <div className="card__side card__side--back card__side--back-3">
          <div className="card__cta">
            <div className="card__price-box">
              <span className="card__price-value-1">
                <br />
                {text}
              </span>
            </div>
          </div>
        </div>
      </div>
 */
