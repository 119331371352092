import React, { Component } from "react";
import "./services-section.css";
import ServiceCard from "./service-cards/service-card.js";
//import Carousel from "react-multi-carousel";
import Carousel from "react-elastic-carousel";
import axios from "axios";
import Seperator from "../../UI/seperator/seperator";

import "react-multi-carousel/lib/styles.css";
import Slider from "react-perfect-slider";
import Slide1 from "./service-slides/slide1";
import Slide2 from "./service-slides/slide2";

// import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import Button from "@mui/material/Button";

import kid from "../../assets/services/kids2.jpg";
import adult from "../../assets/services/adult2.jpg";
import infant from "../../assets/services/infants2.jpg";
import disabled from "../../assets/services/disability.jpg";
import aqua from "../../assets/services/aqua2.png";

const info = [
  {
    title: "Kids Training Program",
    image: kid,
    desc: [
      "We all want our kids not be afraid of water and to learn to swim, not only for safety but also for their enjoyment.",
      "We'll introduce them to water, build confidence and provide a great foundation for a lifetime of swimming. Also, developing all of the skills needed for children to be competent, confident and safe in the water.",
    ],
  },
  {
    title: "Adults Training Program",
    image: adult,
    desc: [
      "We teach adults from having zero swimming skills to being capable of swimming at least 100m without any aid. Therefore, no matter which level you are currently at, our Adult Swimming Program will be designed according to your learning needs.",
    ],
  },
  {
    title: "Infants Training Program",
    image: infant,
    desc: [
      "Babies have a natural affinity with the water and our program gently nurtures this, building confidence, both in the child and often the parent.",
      " Our program allows our babies, at this early age, to make choices about how and when they put their faces in the water. Once our babies have reached 6 months old, they usually have enough strength and co-ordination to try more advanced skills.",
      " The class is full of action, toys and songs! The progression in this level is visible for all to see. Watching a baby that can’t yet walk, initiate their own head down swim is a pretty special thing.",
    ],
  },
  {
    title: "Disability Training Program",
    image: disabled,
    desc: [
      "Swimming has a myriad of benefits, but learning to swim is particularly beneficial for people with disability & special needs. ",
      "Our each individual customized class make for a great balance of teaching challenging new skills, reinforcing current skills whilst making the lessons enjoyable and fun.",
      "Three major goals for a swim program designed for disabled & special needs children:",
    ],
    points: [
      "-Boosting physical activity levels",
      "-Developing self-confidence",
      "-Teaching a lifesaving skill",
    ],
  },
  {
    title: "Aqua Therapy Program",
    image: aqua,
    desc: [
      "It is a safe complement or alternative to traditional land-based therapy that is suitable for children and adults of all ages and fitness levels. It is basically a physical therapy that takes place in a pool under the supervision of a trained professional, using the soothing properties of water. ",
      "A customized treatment program is appeal to those in search of treatment that can heal the body while improving rehabilitation timeframes, fitness levels or reducing overall stress levels.",
    ],
  },
];

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];

export class Services extends Component {
  render() {
    return (
      <div className="services-section">
        <h2 className="section-title-services">Services</h2>
        <div style={{ width: "98%", margin: "0 1%" }}>
          <Carousel breakPoints={breakPoints}>
            {info.map((i) => (
              <ServiceCard
                key={i}
                title={i.title}
                image={i.image}
                desc={i.desc}
                points={i.points}
              />
            ))}
          </Carousel>
        </div>

        <Seperator />
      </div>
    );
  }
}

export default Services;

/**
 *     const CustomRightArrow = ({ onClick, ...rest }) => {
      const {
        onMove,
        carouselState: { currentSlide, deviceType },
      } = rest;
      // onMove means if dragging or swiping in progress.
      return (
        <Button className="rightButton" onClick={() => onClick()}>
          <ArrowForwardIosIcon fontSize={"large"} />
        </Button>
      );
    };

    const CustomLeftArrow = ({ onClick, ...rest }) => {
      const {
        onMove,
        carouselState: { currentSlide, deviceType },
      } = rest;
      // onMove means if dragging or swiping in progress.
      return (
        <Button className="leftButton" onClick={() => onClick()}>
          <ArrowBackIosIcon fontSize={"large"} />
        </Button>
      );
    };
 * 
 * 
 * 



 *         <Carousel
          swipeable={true}
          ssr={false}
          partialVisbile={false}
          responsive={responsive}
          containerClass="carousel-container-with-scrollbar"
          centerMode={true}
          arrows={false}
          transitionDuration={800}
          infinite={true}
          autoPlay={this.props.deviceType !== "mobile" ? true : false}
          autoPlaySpeed={15000}
          arrows={true}
          customRightArrow={<CustomRightArrow />}
          customLeftArrow={<CustomLeftArrow />}
        >
          {info.map((i) => (
            <ServiceCard key={i} title={i.text} image={i.image} />
          ))}
        </Carousel>
        <div className="seperator-services"></div>
 */

/**
 *
 *           <div className="three-cards-container">
            {info.map((i) => (
              <ServiceCard key={i} title={i} />
            ))}
          </div>
          <div className="three-cards-container">
            {info3.map((i) => (
              <ServiceCard key={i} title={i} />
            ))}
          </div>
 */

/**
 *                <Carousel
          swipeable={true}
          ssr={false}
          partialVisbile={false}
          responsive={responsive}
          containerClass="carousel-container-with-scrollbar"
          centerMode={true}
          arrows={false}
          transitionDuration={100}
          infinite={true}
          autoPlay={this.props.deviceType !== "mobile" ? true : false}
          autoPlaySpeed={2500}
        >
          {info.map((i) => (
            <ServiceCard key={i} title={i.text} image={i.image} />
          ))}
        </Carousel>
 */
