import React, { Component } from "react";
import BlogBox from "./blog-box/blog-box";
import "./blog-pages.css";
import axios from "axios";

// import Pagination from "@mui/lab/Pagination";
import Pagination from "@material-ui/lab/Pagination";
import BlogPage from "./blog-page/blog-page";
import { Helmet } from "react-helmet";

export class BlogPages extends Component {
  state = {
    data: [],
    articles: [],
    articlesPages: [],
    blogPagesArr: [],
    value: 1,
  };
  // https://swimjo.herokuapp.com
  datadata = async () => {
    await axios(
      "https://swimjo-cms-8c30864f9588.herokuapp.com/api/articles"
    ).then((i) => {
      let dataList = i["data"];
      console.log("Articles " + i["data"]);
    });
  };

  getdata = () => {
    const ar = JSON.parse(localStorage.getItem("articles"));
    console.log("arrrr");
    console.log(ar);
    let articlesComps = ar.data.map((data) => (
      <BlogBox
        data={data["attributes"]}
        id={data.id}
        getBlog={this.props.getBlog}
      />
    ));

    const articles = Array(Math.ceil(articlesComps.length / 3))
      .fill()
      .map((_, index) => index * 3)
      .map((begin) => articlesComps.slice(begin, begin + 3));

    articles.map((data) => <BlogPage pagesData={data} />);

    const articlesPages = Array(Math.ceil(articles.length / 4))
      .fill()
      .map((_, index) => index * 3)
      .map((begin) => articles.slice(begin, begin + 4));

    const blogPagesArr = articlesPages.map((data) => (
      <BlogPage pageData={data} />
    ));
    this.setState({ articles: articles, blogPagesArr: blogPagesArr });

    // await axios("https://swimjo.herokuapp.com/articles").then((i) => {
    //   let dataList = i["data"];
    //   console.log(i["data"]);
    //   dataList.sort(function (a, b) {
    //     return a["id"] - b["id"];
    //   });

    //   let articlesComps = dataList.map((data) => (
    //     <BlogBox data={data} getBlog={this.props.getBlog} />
    //   ));

    //   const articles = Array(Math.ceil(articlesComps.length / 3))
    //     .fill()
    //     .map((_, index) => index * 3)
    //     .map((begin) => articlesComps.slice(begin, begin + 3));

    //   articles.map((data) => <BlogPage pagesData={data} />);

    //   const articlesPages = Array(Math.ceil(articles.length / 4))
    //     .fill()
    //     .map((_, index) => index * 3)
    //     .map((begin) => articles.slice(begin, begin + 4));

    //   const blogPagesArr = articlesPages.map((data) => (
    //     <BlogPage pageData={data} />
    //   ));
    //   this.setState({ articles: articles, blogPagesArr: blogPagesArr });
    // });
  };

  componentWillMount() {
    this.getdata();
  }

  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ activePage: pageNumber });
  }

  hewww = () => {
    this.props.getBlog();
  };

  render() {
    const page = this.state.blogPagesArr[this.state.value - 1];
    const handleChange = (event, value) => {
      this.setState({ value: value });
    };
    //getBLog={this.getBLog}
    return (
      <div className="blog-page">
        <Helmet>
          <title>Blogs - Learn More About Swimming </title>
          <meta
            name="description"
            content="Latest blogs about swimming, swimming for disabilities, swimming benefits and swimming programs."
          />
        </Helmet>
        {page}{" "}
        <Pagination
          className="pagination"
          count={this.state.blogPagesArr.length}
          onChange={handleChange}
          size="small"
          color="primary"
        />
      </div>
    );
  }
}

export default BlogPages;
