import React, { Component } from "react";
import "./mobilenav.css";
import MenuItem from "@material-ui/core/MenuItem";
import HomeIcon from "@material-ui/icons/Home";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import PoolIcon from "@material-ui/icons/Pool";
import PeopleIcon from "@material-ui/icons/People";
import DescriptionIcon from "@material-ui/icons/Description";
import InfoIcon from "@material-ui/icons/Info";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import logo from "../../../assets/swimjo-logo.png";

import { Link } from "react-router-dom";

const promise1 = new Promise((resolve, reject) => {
  resolve(window.location.pathname === "/");
});

class MenuBar extends Component {
  onClickClose = () => {
    this.props.handleClick(false);
  };

  check(section) {
    promise1.then((value) => {
      if (value === true) {
        this.props.refObj[section].current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      } else {
        window.location.pathname = "/";
      }
    });
  }
  render() {
    const { menuAnimation } = this.props;

    return (
      <div
        className={`${menuAnimation}`}
        style={{
          animationFillMode: "forwards",
          backgroundColor: "#008fc9",
          zIndex: "2200",
          position: "fixed",
        }}
      >
        <div className="mobile-menu-logo-container">
          <img
            src={logo}
            alt="website-logo"
            className="mobile-menu-container"
          />
        </div>

        <MenuItem
          exact
          component={Link}
          to="/"
          onClick={() => {
            this.check("homeSection");
            this.onClickClose();
          }}
        >
          <HomeIcon className="mobile-menu-icons" />
          <div className="buttonText">Home</div>
        </MenuItem>
        <MenuItem
          exact
          component={Link}
          to="/"
          onClick={() => {
            this.check("aboutusSection");
            this.onClickClose();
          }}
        >
          <InfoIcon className="mobile-menu-icons" />
          <div className="buttonText">About Us</div>
        </MenuItem>
        <MenuItem
          exact
          component={Link}
          to="/"
          onClick={() => {
            this.check("servicesSection");
            this.onClickClose();
          }}
        >
          <PoolIcon className="mobile-menu-icons" />
          <div className="buttonText">Services</div>
        </MenuItem>

        <MenuItem
          exact
          component={Link}
          to="/"
          onClick={() => {
            this.check("gallerySection");
            this.onClickClose();
          }}
        >
          <PhotoCameraIcon className="mobile-menu-icons" />
          <div className="buttonText">Gallery</div>
        </MenuItem>
        <MenuItem
          exact
          component={Link}
          to="/"
          onClick={() => {
            this.check("ourTeamSection");
            this.onClickClose();
          }}
        >
          <PeopleIcon className="mobile-menu-icons" />
          <div className="buttonText">Our Team</div>
        </MenuItem>
        <MenuItem
          exact
          component={Link}
          to="/blog-page"
          onClick={() => {
            this.onClickClose();
          }}
        >
          <DescriptionIcon className="mobile-menu-icons" />
          <div className="buttonText">Blogs</div>
        </MenuItem>
        <MenuItem
          exact
          component={Link}
          to="/"
          onClick={() => {
            this.check("contactSection");
            this.onClickClose();
          }}
        >
          <PermContactCalendarIcon className="mobile-menu-icons" />
          <div className="buttonText">Contact Us</div>
        </MenuItem>
      </div>
    );
  }
}

export default MenuBar;
