import React, { Component } from "react";
import "./slide.css";
import ServiceCard from "../service-cards/service-card";

const info2 = [
  [
    {
      title: "Blind Friendly Swim Classes",
      time: "30 minutes",
      text: "Every person with a visual impairment has the right to participate in the sport of their choice. With teaching Blind people how to swim they will have more self-confidence and open up to the possibility that they really can pursue their dreams in both sports and life. Also, leave sedentary isolated lives and they gain skills, confidence and joy.",
    },
    {
      title: "Deaf Friendly Swim Classes",
      time: "30 minutes",
      text: "Swimming is one of the sports deaf people most want to take part in, however, a number of challenges and additional barriers deaf young people face. We know that these barriers can be overcome; by making small changes to swimming lessons, our coaches can ensure that deaf young people are fully included.",
    },
    {
      title: "Disability & Special Needs Swim Classes",
      time: "30 minutes",
      text: "Swimming has a myriad of benefits for all children, but learning to swim is particularly beneficial for children with disability & special needs. Our each individual customized class make for a great balance of teaching challenging new skills, reinforcing current skills whilst making the lessons enjoyable and fun. Three major goals for a swim program designed for disabled & special needs children: - Boosting physical activity levels - Developing self-confidence - Teaching a life saving skill",
    },
  ],
  [
    {
      title: "Swim at Your Place",
      time: "30 minutes",
      text: "Do you have a pool at home or another private location? Let us come to you and take the stress out of swimming lessons. Our qualified teachers bring all the equipment to you and structure the lesson to suit your child’s needs and abilities. It can’t get any easier!",
    },
    {
      title: "Swim for Fitness",
      time: "45 minutes and up",
      text: "Swimming is a low-impact workout which improves the cardiovascular fitness and muscular strength of every individual. For swimmers who desire to take up swimming as a way to boost cardiovascular fitness and endurance while keeping your joints healthy, our Swim for Fitness programme is perfect for you.",
    },
    {
      title: "Swim to Compete",
      time: "45 minutes and up",
      text: "The Swim to Compete program is an endurance, strength, and swim team program that takes swimmers who already have knowledge of the competitive swimming strokes and want to refine their swimming skills. In line with the coaches’ direction, swimmers will be expected to take responsibility for their training progress and continuously work on their competitive skills. Considering the goals and performance of each swimmer, training plans will work towards achieving their fullest potential.",
    },
  ],
];

const info = [
  {
    title: "Kids Training Program",
    desc: "Babies have a natural affinity with the water and our program gently nurtures this, building confidence, both in the child and often the parent. Our program allows our babies, at this early age, to make choices about how and when they put their faces in the water.Once our babies have reached 6 months old, they usually have enough strength and co-ordination to try more advanced skills. The class is full of action, toys and songs! The progression in this level is visible for all to see. Watching a baby that can’t yet walk, initiate their own head down swim is a pretty special thing.",
  },
  "Adults Training Program",
  "Infants Training Program",
];

const info3 = ["Disability Training Program", "Aqua Therapy Program"];

export class Slide1 extends Component {
  render() {
    return (
      <div className="slide-container">
        <div className="three-cards-container">
          {info.map((i) => (
            <ServiceCard key={i} />
          ))}
        </div>
        <div className="three-cards-container">
          {info3.map((i) => (
            <ServiceCard key={i} />
          ))}
        </div>
      </div>
    );
  }
}

export default Slide1;

/**
 * 
 * {info[0].forEach((i) => {
            <ServiceCard title={i.title} time={i.time} text={i.text} />;
          })}


 * {info[1].map((i) => {
            <ServiceCard
              title={i["title"]}
              time={i["time"]}
              text={i["text"]}
            />;
          })}
 */
