import React, { Component } from "react";
import "./ourteam-section.css";
import OurTeamCarousel from "./ourteam-carousel";
import Seperator from "../../UI/seperator/seperator";

export class OurTeam extends Component {
  render() {
    return (
      <div className="ourteam-section">
        <h2 className="section-title-team">Our Team</h2>
        <OurTeamCarousel />
        <Seperator />
      </div>
    );
  }
}

export default OurTeam;
