import React, { Component } from "react";
// import FbImageGrid from "react-fb-image-grid";
// import Photogrid from "react-facebook-photo-grid";
import { Gallery } from "react-grid-gallery";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import one from "../../assets/gallery/1.JPG";
// import two from "../../assets/gallery/2.JPG";
// import three from "../../assets/gallery/3.JPG";
import four from "../../assets/gallery/4.JPG";
import five from "../../assets/gallery/5.JPG";
import six from "../../assets/gallery/6.JPG";
import seven from "../../assets/gallery/7.JPG";
import eight from "../../assets/gallery/8.JPG";
import nine from "../../assets/gallery/9.JPG";
import ten from "../../assets/gallery/10.JPG";
import eleven from "../../assets/gallery/11.JPG";
// import twelve from "../../assets/gallery/12.JPG";
import thirteen from "../../assets/gallery/13.JPG";

// const IMG = [
//   one,
//   // two,
//   // three,
//   four,
//   five,
//   six,
//   seven,
//   eight,
//   nine,
//   ten,
//   eleven,
//   // twelve,
//   thirteen,
// ];

// const IMG = [
//   { src: one, width: 320, height: 174 },
//   { src: four, width: 320, height: 212 },
//   { src: five, width: 320, height: 212 },
//   { src: six, width: 320, height: 213 },
//   { src: seven, width: 320, height: 183 },
//   { src: eight, width: 240, height: 320 },
//   { src: nine, width: 320, height: 190 },
//   { src: ten, width: 320, height: 148 },
//   { src: eleven, width: 320, height: 213 },
//   { src: thirteen, width: 248, height: 320 },
// ];

const IMG = [
  { original: one },
  { original: four },
  { original: five },
  { original: six },
  { original: seven },
  { original: eight },
  { original: nine },
  { original: ten },
  { original: eleven },
  { original: thirteen },
];

function renderLeftNav(onClick, disabled) {
  return (
    <button
      type="button"
      className="image-gallery-left-nav"
      aria-label="Prev Slide"
      disabled={disabled}
      onClick={onClick}
    >
      <button size={30} color="#000">
        Left
      </button>
    </button>
  );
}

function renderRightNav(onClick, disabled) {
  return (
    <button
      type="button"
      className="image-gallery-right-nav"
      aria-label="Next Slide"
      disabled={disabled}
      onClick={onClick}
    >
      <button size={30} color="#000">
        Right
      </button>
    </button>
  );
}
export class ImgGallery extends Component {
  render() {
    return (
      <div className="img-gallery">
        {/* <Gallery images={IMG} width={95} enableImageSelection={false}></Gallery> */}
        <ImageGallery
          items={IMG}
          showBullets={false}
          showIndex={false}
          showThumbnails={true}
          lazyLoad={false}
          showPlayButton={false}
          showNav={true}
          showFullscreenButton={false}
        />
      </div>
    );
  }
}

export default ImgGallery;
