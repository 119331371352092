import React, { Component } from "react";
import "./gallery-section.css";
import ImgGallery from "./img-gallery";
import ill1 from "../../assets/ill6.png";
import Seperator from "../../UI/seperator/seperator";

export class Gallery extends Component {
  render() {
    return (
      <div className="gallery-section">
        <h2 className="section-title-gallery">Gallery</h2>
        <div className="gallery-flex">
          <div
            className="gallery-text"
            data-aos="fade-left"
            data-aos-duration={800}
          >
            <p className="gallery-text-par">
              Swimming is one of the most amazing confidence-building activities
              for kids.
            </p>
            <p className="gallery-text-par">
              We place children in one of the levels based on their age and
              experience with swimming. Breaking down swimming into steps and
              taking them one at a time allows the kids we teach to grow
              confident as they master one skill and move on to the next.
            </p>
            <p className="gallery-text-par">
              When kids are placed in an environment where it is safe to fail as
              well as safe to succeed, they will naturally become more
              confident.
            </p>
          </div>
          <div
            className="gallery-ImgGallery"
            style={{ zIndex: "100" }}
            data-aos="fade-right"
            data-aos-duration={800}
          >
            <ImgGallery />
          </div>
        </div>
        {/* <div class="bubbles">
          <div class="bubble"></div>
          <div class="bubble"></div>
          <div class="bubble"></div>
          <div class="bubble"></div>
          <div class="bubble"></div>
          <div class="bubble"></div>
          <div class="bubble"></div>
          <div class="bubble"></div>
          <div class="bubble"></div>
          <div class="bubble"></div>
        </div> */}
        <Seperator />
      </div>
    );
  }
}

/**
 *             <div style={{ textAlign: "center" }}>
              <img className="gallery-text-ill" src={ill1} />
            </div>
 */

export default Gallery;
