import React, { Component } from "react";
import axios from "axios";
import "./article-page.css";
// import CircularProgress from "@mui/material/CircularProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Helmet } from "react-helmet";

export class ArticlePage extends Component {
  state = {
    article: null,
    content: null,
    title: null,
  };

  getArticleFromApi = async () => {
    // https://swimjo.herokuapp.com
    await axios(
      "https://swimjo-cms-8c30864f9588.herokuapp.com/api/articles"
    ).then((i) => {
      const articleArr = i.data.data;
      const artId = this.getArticleFromUrl();
      console.log(artId);
      const aa = articleArr.filter((a) => {
        console.log(a["id"]);
        if (a["id"] === artId) {
          return a;
        }
      });
      console.log(aa[0]["attributes"]["title"]);
      var array = aa[0]["attributes"]["content"].split("\n\n");
      this.setState({ content: array });
      this.setState({ title: aa[0]["attributes"]["title"] });
      this.setState({ article: aa[0]["attributes"] });
    });
  };

  componentWillMount() {
    this.getArticleFromApi();
  }

  sliceLine(s) {
    const sliced = s.slice(0, 160);
    return sliced + "...";
  }

  getArticleFromUrl = () => {
    const url = window.location.pathname;
    const idd = url.split("/")[url.split("/").length - 2];
    this.setState({ arId: idd });
    return parseInt(idd);
  };

  render() {
    const langAlignment = (lang) => {
      if (lang === "ar") {
        return { textAlign: "right", right: "20px", direction: "rtl" };
      } else if (lang === "en") {
        return { textAlign: "left", left: "20px", direction: "ltr" };
      } else {
        return { textAlign: "right", right: "20px", direction: "rtl" };
      }
    };

    return (
      <div className="article-page">
        {this.state.article === null ? (
          <CircularProgress />
        ) : (
          <div className="article-inner-page">
            <Helmet>
              <title>{this.state.article["title"]}</title>
              <meta
                name="description"
                content={this.sliceLine(this.state.content[0])}
              />
            </Helmet>
            <div className="article-title-container">
              <h1
                className="article-title"
                style={langAlignment(this.state.article["lang"])}
              >
                {this.state.article["title"]}
              </h1>
              <div>
                <div className="wave wave1"></div>
                <div className="wave wave2"></div>
                <div className="wave wave3"></div>
                <div className="wave wave4"></div>
              </div>
            </div>
            <div
              className="article-content-container"
              style={langAlignment(this.state.article["lang"])}
            >
              {this.state.content.map((i) => {
                if (i.includes("#")) {
                  const h = i.replaceAll("#", "");
                  return (
                    <span className="article-page-header">
                      {h}
                      <br />
                      <br />
                    </span>
                  );
                } else {
                  return (
                    <span className="article-page-para">
                      {i}
                      <br />
                      <br />
                    </span>
                  );
                }
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
}

/**
 * {this.state.article !== null ? (
          <CircularProgress />
        ) : (
 */

export default ArticlePage;

/** 
 
  getArticle() {
    const article = JSON.parse(localStorage.getItem("article"));
    console.log(article);
    this.setState({ article: article });
  }




  showArticle() {
    const article = JSON.parse(localStorage.getItem("article"));
    var array = article["content"].split("\n\n");

    this.setState({ content: array });
    console.log(array);
    return array;
  }
*/
