import React, { Component, Fragment } from "react";
// import Card from "@material-ui/core/Card";
// import Button from "@material-ui/core/Button";
// import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import emailjs from "emailjs-com";

//import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";

import "./contact-form.css";

export class ContactFrom extends Component {
  state = {
    name: "",
    email: "",
    message: "",
    phoneNumber: "",
    form: false,
  };

  isStateFull = (e) => {
    if (
      this.state.name &&
      this.state.email &&
      this.state.phoneNumber &&
      this.state.message
    ) {
      return true;
    } else {
      return false;
    }
  };

  sendEmail = (e) => {
    e.preventDefault();
    console.log(this.state);
    //service_8ea87ra
    //template_b17dmop
    //user_lOPRu8s2vsIuZFuVNrLiU
    emailjs
      .send(
        "service_fudnego",
        "template_5pw4t8j",
        this.state,
        "user_oGk6T9qq030b5pAbLtycy"
      )
      .then(
        (result) => {
          console.log(result.text);
          this.setState({ form: true });
          alert("Message Sent Successfully");
          window.location.reload(false);
        },
        (error) => {
          console.log(error.text);
          this.setState({ form: false });
          alert("Message Not Sent");
        }
      );
  };

  changeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleParagraphChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value.split("\\n").join("\n"),
    });
  };

  render() {
    const contactform = (
      <Fragment>
        <form noValidate onSubmit={this.sendEmail}>
          <div className="email-name-container">
            <input
              type="text"
              name="name"
              onChange={this.changeHandler}
              className="input-text-field"
              placeholder="Name"
              required
            />
            <input
              type="email"
              name="email"
              onChange={this.changeHandler}
              className="input-text-field"
              placeholder="E-mail"
              required
            />
            <input
              type="number"
              name="phoneNumber"
              onChange={this.changeHandler}
              className="input-text-field"
              placeholder="Phone Number"
              required
            />
          </div>
          <textarea
            type="text"
            name="message"
            onChange={this.handleParagraphChange}
            className="input-message-field"
            placeholder="Message"
            required
          />

          <Button
            variant="contained"
            color="primary"
            type="submit"
            className="ContactFormBtn"
            disabled={this.isStateFull() ? false : true}
          >
            <span className="ContactFormBtnTitle">Submit</span>
            {this.state.loading && <CircularProgress size={30} />}
          </Button>
        </form>
      </Fragment>
    );
    return (
      <Fragment>
        <Card className="Contact-Form-Box">{contactform}</Card>
      </Fragment>
    );
  }
}

export default ContactFrom;

/**
 *    const contactform =
      this.state.form === false ? (
        <Fragment>
          <form noValidate onSubmit={this.sendEmail}>
            <div className="email-name-container">
              <input
                type="text"
                name="name"
                onChange={this.changeHandler}
                className="input-text-field"
                placeholder="Name"
                required
              />
              <input
                type="email"
                name="email"
                onChange={this.changeHandler}
                className="input-text-field"
                placeholder="E-mail"
                required
              />
            </div>
            <textarea
              type="text"
              name="message"
              onChange={this.handleParagraphChange}
              className="input-message-field"
              placeholder="Message"
              required
            />

            <Button
              variant="contained"
              color="primary"
              type="submit"
              className="ContactFormBtn"
            >
              <span className="ContactFormBtnTitle">Submit</span>
              {this.state.loading && <CircularProgress size={30} />}
            </Button>
          </form>
        </Fragment>
      ) : (
        <div
          className="Enquiry-Form-Box-top"
          style={{ textAlign: "center", margin: "75px 0" }}
        >
          <h1 className="EnquiryFormTitle">Successfully Sent</h1>

        </div>
      );     
 */
