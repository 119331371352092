import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
  backDrop: {
    position: "absolute",
    backgroundColor: "grey",
    top: "0",
    width: "100%",
    height: "100%",
    zIndex: "2200",
    margin: "0",
    opacity: "0.4",
    position: "fixed",
  },
  display: {
    display: "none",
    zIndex: "2200",
  },
};

class BackDrop extends Component {
  onClickClose = () => {
    this.props.handleClick(false);
  };
  render() {
    const { classes } = this.props;
    let display = classes.display;
    if (this.props.menuAnimation === true) {
      display = classes.backDrop;
    } else if (this.props.menuAnimation === false) {
      display = classes.display;
    }

    return <div className={display} onClick={this.onClickClose}></div>;
  }
}

export default withStyles(styles)(BackDrop);
