import React, { Component } from "react";
import "./home-section.css";
import { Helmet } from "react-helmet";
import "aos/dist/aos.css";

import Seperator from "../../UI/seperator/seperator";

const promise1 = new Promise((resolve, reject) => {
  resolve(window.location.pathname === "/");
});

export class HomeSection extends Component {
  check(section) {
    promise1.then((value) => {
      if (value === true) {
        this.props.refObj[section].current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      } else {
        window.location.pathname = "/";
      }
    });
  }
  render() {
    return (
      <div className="home-section">
        <Helmet>
          <title>Swim Jordan - The Leading Swimming Academy in Jordan</title>
          <meta
            name="description"
            content="One on One swimming classes for all & persons with disabilities of both genders and all ages by former internationally certified swimmers"
          />
        </Helmet>
        <div className="contents">
          <h1 className="home-title">Swim Jordan</h1>
          <h1 className="home-title">Swim Jordan</h1>
          <p className="home-desc">
            one on one swimming classes for all & persons with disabilities of
            both genders by former swimmers
          </p>
          <div
            className="home-button"
            onClick={() => {
              this.props.refObj["contactSection"].current.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "nearest",
              });
            }}
          >
            <p className="home-button-text">Contact Us</p>
          </div>
        </div>
        <div class="ocean">
          <div class="wave-bg"></div>
          <div class="wave-bg"></div>
        </div>
        <Seperator />
      </div>
    );
  }
}

export default HomeSection;
