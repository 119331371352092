import React, { Component } from "react";
//import Carousel from "react-multi-carousel";
//import "react-multi-carousel/lib/styles.css";
import "./ourteam-section.css";

import TeamCard from "./ourteam-card/teamcard";
import Carousel from "react-elastic-carousel";
import axios from "axios";
//import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
//import { Carousel } from "react-responsive-carousel";

// import instructor1 from "../../assets/coaches/coach1.jpg";
// import instructor2 from "../../assets/coaches/coach4.jpg";
// import instructor3 from "../../assets/coaches/coach3.jpg";
// import instructor4 from "../../assets/coaches/coach2.jpg";
// import instructor5 from "../../assets/coaches/coach5.jpg";

import ahmad from "../../assets/coaches/ahmad.png";
import khalid from "../../assets/coaches/khalid.png";
import mmoud from "../../assets/coaches/mmoud.png";
import nour from "../../assets/coaches/nour.png";
import sharif from "../../assets/coaches/sharif.png";
import yara from "../../assets/coaches/yara.png";
import zaid from "../../assets/coaches/zaid.png";

const intructorData = [
  {
    img: zaid,
    name: "Zaid Hamidi",
    desc: [
      "-	Former national team swimmer",
      "-	Achieved medals in local and international swimming championships",
      "-	Holder of Jordanian records",
      "-	Former head coach for Al Hussein Youth City Club",
      "-	Certified swimming coach by national & international institutions",
    ],
  },
  {
    img: khalid,
    name: "Khalid Al Hemsi",
    desc: [
      " -	Former national team swimmer",
      "-	Achieved medals in local and international swimming championships",
      "-	Holder of Jordanian records",
      "-	Former member in the Jordan Swimming Federation",
      "-	Certified swimming coach by national & international institutions",
    ],
  },
  {
    img: mmoud,
    name: "Mahmoud Sukkarieh",
    desc: [
      "-	Former national team swimmer",
      "-	Achieved medals in local and international swimming championships",
      "-	Holder of Jordanian records",
      "-	Former head coach for Al Hussein Youth City Club",
      "-	Certified swimming coach by national & international institutions",
    ],
  },
  {
    img: nour,
    name: "Noor Rodan",
    desc: [
      " -	+3 years of swimming coaching experience",
      "-	Experience in dealing with aqua-phobia cases",
      "-	Certified life guard",
    ],
  },
  {
    img: sharif,
    name: "Sharif Lutfi",
    desc: [
      "- Former national team swimmer",
      "- Certified swimming coach by national & international institutions",
      "- Disability friendly swimming accredited",
      "- Autism swimming provider",
      "- Certified life guard ",
      "- Sports rehabilitation certified",
      "- Certified CPR",
    ],
  },
  {
    img: yara,
    name: "Sharif Lutfi",
    desc: [
      " -	Access & Inclusion swim teacher",
      "-	Accredited certificate by Austiswim in Australia",
    ],
  },
  {
    img: ahmad,
    name: "Sharif Lutfi",
    desc: [
      " -	Former swimmer with Al Hussein Youth city club.",
      "-	Achieved medals in many local swimming championships.",
      "-	Certified Swimming coach.",
    ],
  },
];

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];

function OurTeamCarousel() {
  // const [items, setItems] = useState([1, 2, 3, 4, 5]);

  // const addItem = () => {
  //   const nextItem = Math.max(1, items.length + 1);
  //   setItems([...items, nextItem]);
  // };

  // const removeItem = () => {
  //   const endRange = Math.max(0, items.length - 1);
  //   setItems(items.slice(0, endRange));
  // };

  return (
    <Carousel breakPoints={breakPoints}>
      {intructorData.map((i) => (
        <TeamCard img={i.img} name={i.name} desc={i.desc} />
      ))}
    </Carousel>
  );
}

export default OurTeamCarousel;

/**
 * 
 * export class OurTeamCarousel extends Component {
  render() {
    return (
      <Carousel showArrows={true} style={{ height: "300px" }}>
        {intructorData.map((i) => (
          <TeamCard img={i.img} name={i.name} />
        ))}
      </Carousel>
    );
  }
}
 * 
 * 
 * 
 * 
 * 
 * 
 * <div className="three-cards-container">
          {intructorData.map((i) => (
            <TeamCard img={i.img} name={i.name} />
          ))}
        </div>
 */

/**
  <Carousel
        ssr={false}
        swipeable={true}
        partialVisbile={false}
        responsive={responsive}
        containerClass="carousel-container-with-scrollbar"
        centerMode={true}
        arrows={false}
        transitionDuration={100}
        infinite={true}
        autoPlay={this.props.deviceType !== "mobile" ? true : false}
        autoPlaySpeed={2500}
        className="carousell"
      >
        {intructorData.map((i) => (
          <TeamCard img={i.img} name={i.name} />
        ))}
      </Carousel>
 */
