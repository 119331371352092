import React, { Component } from "react";
import "../ourteam-section.css";
import "./teamcard.css";

export class TeamCard extends Component {
  render() {
    const { img, name, desc } = this.props;
    return (
      <div className="card">
        <div className="card__side card__side--front">
          <div className="card__picture card__picture--3">
            <img className="image" src={img} alt="img" />
            &nbsp;
          </div>
          {/* <h4 className="card__heading">
            <span className="card__heading-span ">{name}</span>
          </h4> */}
        </div>
        <div className="card__side card__side--back card__side--back-3">
          <div className="card__cta">
            <div className="card__price-box">
              <span className="card__price-value-1">
                <br />
                <ul>
                  {desc.map((i) => (
                    <p className="team-desc">{i}</p>
                  ))}
                </ul>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/**
 *           <div className="ourteam-slide">
        <div className="ourteam-slide-top">
          <img src={this.props.img} className="team-img" />
        </div>
        <div className="ourteam-slide-bottom">
          <h3 className="name-style">{this.props.name}</h3>
        </div>
      </div>
 */

export default TeamCard;
