import React, { Component } from "react";
import "../../css/main.css";
import "./header-section.css";
// import MenuIcon from "@mui/icons-material/Menu";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import InstagramIcon from "@mui/icons-material/Instagram";

import MenuIcon from "@material-ui/icons/Menu";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";

import { Link } from "react-router-dom";
import logo from "../../assets/swimjo-logo.png";

const promise1 = new Promise((resolve, reject) => {
  resolve(window.location.pathname === "/");
});

export class HeaderSection extends Component {
  check(section) {
    promise1.then((value) => {
      if (value === true) {
        this.props.refObj[section].current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      } else {
        window.location.pathname = "/";
      }
    });
  }
  render() {
    return (
      //className="menuDeskDisplay"
      //className="menuMobileDisplay"
      /**
       * <div>
            <MenuIcon className="menuLogo" onClick={this.onClickClose} />
          </div>
       */
      <header className="header-bottom">
        <div className="header-menu">
          <img className="swimjo-logo menuDeskDisplay" src={logo} alt="logo" />
          <div className="menuDeskDisplay">
            <ul>
              <Link to="/">
                <div onClick={() => this.check("homeSection")}>Home</div>
              </Link>
              <Link to="/" onClick={() => this.check("aboutusSection")}>
                About us
              </Link>
              <Link to="/" onClick={() => this.check("servicesSection")}>
                Services
              </Link>
              <Link to="/" onClick={() => this.check("gallerySection")}>
                Gallery
              </Link>
              <Link to="/" onClick={() => this.check("ourTeamSection")}>
                Our Team
              </Link>
              <Link exact to="/blog-page">
                Blogs
              </Link>
              <Link to="/" onClick={() => this.check("contactSection")}>
                Contact us
              </Link>
            </ul>
          </div>
          <div className="social-icons faceBook">
            <a href="https://www.facebook.com/swimjordan.jo/">
              <FacebookIcon className="social-icon-font" />
            </a>
            <a href="https://www.instagram.com/swimjordan.jo/?fbclid=IwAR38ibV2TrvvGL5bf__35kSakoT8EKDaeRQSAztwFUAVCiVbyxOZXgb81co">
              <InstagramIcon className="social-icon-font instaGram" />
            </a>
          </div>
          <div className="menuMobileDisplay">
            <MenuIcon
              className="menuLogo"
              onClick={() => this.props.handleClick(true)}
            />
          </div>
        </div>
      </header>
    );
  }
}

/**
 * onClick={() => {
                this.props.refObj["gallerySection"].current.scrollIntoView({
                  behavior: "smooth",
                });
              }}
 */

export default HeaderSection;
