import React, { Component } from "react";
import BlogBox from "../blog-box/blog-box";
import "../blog-pages.css";

export class BlogPage extends Component {
  render() {
    return (
      <div>
        {this.props.pageData.map((data) => (
          <div className="blog-section">{data}</div>
        ))}
      </div>
    );
  }
}

export default BlogPage;
