import React, { Component } from "react";
import axios from "axios";
import "./aboutus-section.css";
import vid from "../../assets/vid.mp4";
import swimmer from "../../assets/ill3.png";
import Aos from "aos";
import "aos/dist/aos.css";

import "../../../node_modules/video-react/dist/video-react.css";
import { Player } from "video-react";
import Seperator from "../../UI/seperator/seperator";

export class AboutUs extends Component {
  getdata() {
    axios("https://swimjo.herokuapp.com/articles").then((i) => {
      console.log(i["data"]);
      this.setState({ img: i["data"][0]["image"]["url"] });
    });
  }

  componentDidMount() {
    Aos.init({ duration: 4000, delay: 150 });
  }
  render() {
    return (
      <div className="aboutus-section">
        <h2 className="section-title-about">About Us</h2>
        <div className="about-flex">
          <div
            className="about-ill"
            style={{ zIndex: "100" }}
            data-aos="fade-right"
            data-aos-duration={800}
          >
            <Player autoPlay={false} src={vid} />
          </div>
          <div className="about-text-container">
            <h1
              className="about-text"
              data-aos="fade-left"
              data-aos-duration={800}
            >
              Swim Jordan believes that every person of all abilities &
              disabilities especially blind and deaf, have the right to swim.
              Thus, through a fun & well planned 1:1 class, we'll ensure you
              reach your goal guided by highly qualified former national
              swimmers.
            </h1>
            <img
              src={swimmer}
              data-aos="fade-right"
              data-aos-duration={800}
              className="about-ill-img"
            />
          </div>
        </div>
        <div class="bubbles">
          <div class="bubble"></div>
          <div class="bubble"></div>
          <div class="bubble"></div>
          <div class="bubble"></div>
          <div class="bubble"></div>
          <div class="bubble"></div>
          <div class="bubble"></div>
          <div class="bubble"></div>
          <div class="bubble"></div>
          <div class="bubble"></div>
        </div>
        <Seperator />
      </div>
    );
  }
}

/**
 * <Player>
              <source src={vid} style={{ height: "100%", width: "100%" }} />
            </Player>
 */

//style={{ height: "400px", width: "40%" }}

export default AboutUs;
